import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {GoogleAnalyticsService, NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';

import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuswahlComponent } from './auswahl/auswahl.component';
import { PriceComponent } from './price/price.component';
import { PersonalComponent } from './personal/personal.component';
import { PersonalPlzComponent } from './personal-plz/personal-plz.component';
import { MailSendComponent } from './mail-send/mail-send.component';
import {Personal} from './Models/personal.model';
import {PersonalService} from './personal.service';
import {PriceService} from "./price.service";
import {environment} from "../environments/environment";
import {ImageLoaderConfig, IMAGE_LOADER, NgOptimizedImage} from "@angular/common";

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    AuswahlComponent,
    PriceComponent,
    PersonalComponent,
    PersonalPlzComponent,
    MailSendComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    NgxGoogleAnalyticsModule.forRoot(environment.gaTAG),
    NgxGoogleAnalyticsRouterModule,
    FormsModule
  ],
  providers: [
    Personal,
    PersonalService,
    PriceService,
    GoogleAnalyticsService,
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return `${config.src}` }
    }
  ]
})
export class AppModule { }
