import type { OnInit } from '@angular/core';
import {Component, Inject, ViewChild} from '@angular/core';
import {ApiService} from './api.service';
import {LogService} from './log.service';
import {environment} from '../environments/environment';
import type {Personal} from './Models/personal.model';
import {PersonalService} from './personal.service';
import type {IAbfragen, IAbfrageItem, IAbfragenChosen} from './Models/abfragen';
import type {HttpResponse} from '@angular/common/http';
import { Abfragen, AbfragenChosen} from './Models/abfragen.model';
import { saveAs } from 'file-saver';
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {PriceService} from "./price.service";
import {PersonalPlzComponent} from "./personal-plz/personal-plz.component";
import {PersonalComponent} from "./personal/personal.component";

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.css'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  /**
   * Variables used on Page
   */
  /* Alle Abfragen von der API */
  allAbfragen: IAbfragen;
  /* Alle Categorien von der API */
  allCategories: string[];
  /*  */
  currAbfrage;
  currCategory;
  currPersonalPage;
  chosenValues: IAbfragenChosen;
  personal: Personal;
  stepHeading;
  loggingID: string;
  showNextPage;
  title = 'caport-kalkulator-frontend';

  @ViewChild(PersonalPlzComponent) personalPlzCompnChild:PersonalPlzComponent
  @ViewChild(PersonalComponent) personalCompnChild:PersonalComponent

  constructor(@Inject(ApiService) private api: ApiService,
              @Inject(LogService) private logger: LogService,
              @Inject(PersonalService) private personalService: PersonalService,
              @Inject(PriceService) private priceService: PriceService,
              @Inject(GoogleAnalyticsService) private $gaService: GoogleAnalyticsService) {
    this.allCategories = [];
    this.currAbfrage = {};
    this.currCategory = '';
    this.currPersonalPage = '';
    this.chosenValues = new AbfragenChosen();
    this.showNextPage = false;
    // this.selectedValue = {};
    this.stepHeading = 'Auswahl des Typs';
  }

  ngOnInit(): void {
    this.getAbfragen();
  }

  getAbfragen(): void {
    this.api.getAbfragen()
      .subscribe(data => {
        this.allAbfragen = new Abfragen().deserialize(data.body);
        this.logger.log('Logging AllAbfragen');
        this.logger.log(this.allAbfragen);
        Object.keys(this.allAbfragen).forEach(key => {
          this.allCategories.push(key);
        });
        this.allCategories.push('finish');
        this.currCategory = this.allCategories[0];
        this.logger.log(this.allCategories);
        this.refreshScope();
      });
  }

  refreshScope(sub = false): void {
    this.currAbfrage = {};
    // this.selectedValue = {};
    if (this.currCategory === 'finish') {
      this.logger.log('TODO: go to Price Anzeige Seite');
      return;
    }
    let totalCurrAbfragen: IAbfrageItem[];
    if (sub) {
      totalCurrAbfragen = this.getTotalCurrAbfragenPossible(this.chosenValues[this.currCategory].subAbfrage, true);
    }
    else {
      totalCurrAbfragen = this.getTotalCurrAbfragenPossible(this.allAbfragen[this.currCategory]);
    }
    this.logger.log(totalCurrAbfragen);
    if (totalCurrAbfragen.length === 1) {
      this.logger.log("only one Abfrage available");
      this.chooseValue(totalCurrAbfragen[0]);
      return this.nextPage();
    }
    /*if (Object.keys(totalCurrAbfragen).length === 1) {
      this.chooseValue(totalCurrAbfragen[Object.keys(totalCurrAbfragen)[0]]);
      this.nextPage();
    }*/
    this.currAbfrage = totalCurrAbfragen;
    this.showNextPage = true;
  }

  allDoneCategories(): string[] {
    const result: string[] = [];
    this.allCategories.forEach(cat => {
      if (Object.prototype.hasOwnProperty.call(this.chosenValues, cat)) {
        result.push(cat);
      }
    });
    return result;
  }

  getTotalCurrAbfragenPossible(allPossibleAbfragen: IAbfrageItem[], sub = false): any {
    this.logger.log(sub);
    const totalCurrentAbfragen: IAbfrageItem[] = [];
    this.logger.log(allPossibleAbfragen);
    const currentChosenValueIds: string[] = Object.keys(this.chosenValues).map(value => this.chosenValues[value].id);
    const currentChosenValueIdsGrob: string[] = (Object.keys(this.chosenValues).map(value => {
      switch (this.chosenValues[value].id) {
        case 'walmblende':
        case 'profil':
          return 'flachdach';
        case 'satteldach':
        case 'walmdach':
          return 'satteldach';
        case 'pultdach':
          return ['_pultdach', 'satteldach'];
        case 'kvh':
        case 'bsh':
          return 'kvh_bsh';
        default:
          return this.chosenValues[value].id;
      }
    })).flat();
    currentChosenValueIdsGrob.push("default");
    for(const abfrageItem of allPossibleAbfragen) {
      const testedItem: IAbfrageItem = abfrageItem.testAbfrageItemNeeded(sub, currentChosenValueIds, currentChosenValueIdsGrob, this.logger);
      this.logger.log(testedItem);
      if (testedItem) {
        totalCurrentAbfragen.push(testedItem);
      }
    }
    this.logger.log(totalCurrentAbfragen, "totalCurrentAbfragen");
    return totalCurrentAbfragen;
  }

  /**
   * @description saves current selection
   */
  chooseValue(value: IAbfrageItem): void {
    // this.selectedValue = value;
    if (value.sub) {
      this.chosenValues[this.currCategory].subAbfrage = value;
      return;
    }
    this.chosenValues[this.currCategory] = value;
    this.logger.log(value.toString(), "value");
  }


  /**
   * @param catName Categorie Name
   */
  createLinkClass(catName: string): string {
    //let className = 'link-';
    let className = 'link';
    /*if (catName === 'finish') {
      className += 'finish';
    }
    else {
      className += (this.allCategories.indexOf(catName) + 1);
    }*/
    if (Object.prototype.hasOwnProperty.call(this.chosenValues, catName) || catName === this.currCategory) {
      className += '-done';
    }
    if (catName === this.currCategory) {
      className += '-current';
    }
    if (catName === 'finish') {
      className += ' finish'
    }
    // this.logger.log(catName);
    // this.logger.log(this.allCategories.indexOf(catName));
    return className;
  }

  /**
   * @param catName Categorie Name
   */
  createLinkValue(catName: string): string {
    let linkValue = ""
    if (catName === 'finish') {
      linkValue += 'finish';
    }
    else {
      linkValue += (this.allCategories.indexOf(catName) + 1);
    }
    return linkValue;
  }

  /**
   * @description goes to the next Page
   */
  nextPage(): void {
    this.logger.log('nextPage pressed');
    this.showNextPage = false;
    if (this.currCategory === 'finish') {
      switch (this.currPersonalPage) {
        case '':
          this.currPersonalPage = 'zip';
          break;
        case 'zip':
          this.currPersonalPage = 'personal';
          break;
        case 'personal':
          this.currPersonalPage = 'mail-send';
          break;
      }
    }
    if (!Object.prototype.hasOwnProperty.call(this.chosenValues, this.currCategory)) {
      this.showNextPage = true;
      return;
    }
    const selectedValue = this.chosenValues[this.currCategory];
    let currentSubAbfrage = false;
    if (Object.prototype.hasOwnProperty.call(selectedValue, 'subAbfrage')) {
      Object.keys(selectedValue.subAbfrage).forEach(subKey => {
        if (typeof selectedValue.subAbfrage[subKey] === 'object') {
          currentSubAbfrage = true;
        }
      });
      this.logger.log('this.selectedValue.subAbfrage');
      this.logger.log(selectedValue.subAbfrage);
    }
    if (currentSubAbfrage) {
      this.refreshScope(true);
      return;
    }
    if (Object.prototype.hasOwnProperty.call(this.chosenValues[this.currCategory], 'subAbfrage')
      && !Object.prototype.hasOwnProperty.call(selectedValue, 'subAbfrage')) {
      this.chosenValues[this.currCategory].subAbfrage = selectedValue;
    }
    else {
      this.chosenValues[this.currCategory] = selectedValue;
    }
    this.logger.log('this.chosenValues');
    this.logger.log(this.chosenValues);
    // go to next Category
    const currIndex = this.allCategories.indexOf(this.currCategory);
    this.logger.log(this.currCategory);
    this.logger.log(this.currPersonalPage);
    this.currCategory = this.allCategories[currIndex + 1];

    this.refreshScope();
  }

  /**
   * @description downloads PDF-File
   */
  downloadPDF(): void {
    this.api.savePDF(this.chosenValues.getValues(), this.personalService.getPersonal().getArray(), this.loggingID)
      .subscribe((response: Blob) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        // const url = window.URL.createObjectURL(blob);
        //window.open(url);
        saveAs(blob, 'Ihr Angebot von CARPORT.DE.pdf');
        this.$gaService.event("downloadPDF", "kalkulator", this.priceService.getPrice().icon, this.priceService.getPrice().preis);
      }),
      (error: any) => { this.logger.log('Error downloading the file', error) },
      () => console.info('File downloaded successfully');
  }

  /**
   * @description goes to the desired Page
   * @param page to go to
   */
  goToPage(page: string): void {
    this.showNextPage = false;
    this.logger.log(page);
    this.currPersonalPage = '';
    this.currCategory = page;
    this.refreshScope();
  }

  saveLogID(logID: string): void {
    this.loggingID = logID;
  }

  sendMail(): void {
    this.api.sendMail(this.chosenValues.getValues(), this.personalService.getPersonal().getArray(), this.loggingID)
      .subscribe((data: HttpResponse<any>) => {
        if (data.body.text === 'OK') {
          this.currPersonalPage = 'mail-send';
          this.$gaService.event("emailSent", "kalkulator", this.priceService.getPrice().icon, this.priceService.getPrice().preis);
        }
        else {
          this.logger.log(data);
          this.$gaService.event("emailSentError", "kalkulator", this.loggingID);
        }
      });
  }

  mapCategoriesToTitle(): string {
    switch (this.currCategory) {
      case 'Stellplatz':
        return 'Carportlänge wählen';
      case 'Dach':
        return 'Dachform wählen'
      case 'Abbund':
        return 'Abbund - Art der Bearbeitung';
      case 'Sichtschutz':
        return 'Sichtschutz für den Stellplatz wählen';
      case 'finish':
        return this.mapPersonalPagesToTitle();
      default:
        return this.currCategory + ' wählen';
    }
  }

  mapPersonalPagesToTitle(): string {
    switch (this.currPersonalPage) {
      case 'zip':
        return 'Zusätzliche Wünsche';
      case 'personal':
        return 'Persönliche Daten';
      case 'mail-send':
        return 'Anfrage Abgesendet';
      default:
        return 'Geschafft - hier ist Ihr Preis';
    }
  }

  /**
   * @description deletes all inputs
   */
  deleteInputs(): void {
    this.currAbfrage = {};
    this.currCategory = this.allCategories[0];
    this.currPersonalPage = '';
    this.chosenValues = new AbfragenChosen();
    this.showNextPage = false;
    this.refreshScope();
  }

  /**
   *
   * @param obj input Object
   * @returns boolean if obj is empty
   */
  isEmptyObject(obj: IAbfragen): boolean {
    return (obj && (Object.keys(obj).length === 0));
  }

  protected readonly environment = environment;
}
